<template>
  <!-- Begin Mailchimp Signup Form -->
  <div id="mc_embed_signup">
    <form
        action="https://mindsupport.us5.list-manage.com/subscribe/post?u=9ffe9b25e00f55716de94bb7d&amp;id=79018c9b3c&amp;f_id=00d8f5e9f0"
        method="post" id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form" class="validate" target="_blank"
        novalidate>
      <div id="mc_embed_signup_scroll">
        <h2 v-if="title">Subscribe to hear more from us!</h2>
        <div class="indicates-required"><span class="asterisk">*</span>
          indicates required
        </div>
        <div class="mc-field-group">
          <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
          </label>
          <input type="email" value="" name="EMAIL" class="required email"
                 id="mce-EMAIL" required>
          <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
        </div>
        <div id="mce-responses" class="clear foot">
          <div class="response" id="mce-error-response"
               style="display:none"></div>
          <div class="response" id="mce-success-response"
               style="display:none"></div>
        </div>    <!-- real people should not fill this in and expect good
things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;"
             aria-hidden="true"><input type="text"
                                       name="b_9ffe9b25e00f55716de94bb7d_79018c9b3c" tabindex="-1"
                                       value=""></div>
        <div class="optionalParent">
          <div class="clear foot">
            <input type="submit" value="Subscribe" name="subscribe"
                   id="mc-embedded-subscribe" class="button">
            <p class="brandingLogo"><a
                href="http://eepurl.com/hQpd81" title="Mailchimp - email marketing made
easy and fun"><img
                src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"></a></p>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!--End mc_embed_signup-->

</template>
<script>
export default {
  name: "Newsletter",
  props: {
    title: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    window.fnames = new Array();
    window.ftypes = new Array();
    window.fnames[0] = 'EMAIL';
    window.ftypes[0] = 'email';
  }
}
</script>
<style type="text/css">
#mc_embed_signup {
  background: #fff;
  width: 100%;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
}

/* Add your own Mailchimp form style overrides in your site stylesheet
or in this style block.
   We recommend moving this block and the preceding CSS link to the
HEAD of your HTML file. */
</style>